<template>
  <div class="home row">
    <div class="col-xl-10 offset-xl-1 col-12">
      <PageHeaderMain
        :title="pageheader.title"
        :pre-title="pageheader.preTitle"
      />
    </div>
    <div class="col-xl-10 offset-xl-1 col-12">
      <div class="row">
        <div
          v-for="(app, index) in apps"
          :key="index"
          class="application-cards col-xl-3 col-lg-4 col-6"
        >
          <CardApplication
            :app-id="app.applicationId"
            :app-name="app.name"
            :app-icon="app.icon"
            :disabled="userApplications.indexOf(app.applicationId) === -1"
            :app-link="app.urlLink"
            :app-link-target="app.target ? app.target : '_self'"
            :active="selectedApplication && app.applicationId === selectedApplication.applicationId"
            @selectApplication="onSelectApplication($event, app)"
          />
        </div>
      </div>
    </div>
    <organization-selector ref="orgSelector" modal-target="org-home-selector" />
  </div>
</template>

<script>
import {
  PageHeaderMain,
  CardApplication,
  OrganizationSelector
} from '@cendyn/cendyn-frontend-kit';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Home',
  components: {
    PageHeaderMain,
    CardApplication,
    OrganizationSelector
  },
  data() {
    return {
      pageheader: {
        title: 'Home',
        preTitle: 'Cendyn Hospitality Cloud'
      },
      organizations: [],
      modalContent: {
        title: 'Organization Notice',
        body: 'this is body',
        button1: 'Cancel',
        button2: 'Choose',
        button1Action: this.modalCancel,
        button2Action: this.modalChoose,
        showButton2: true
      },
      treeview: {
        data: [],
        title: 'Accounts',
        search: true,
        placeholder: ''
      },
      selectedNodes: [],
      selectedApplication: null
    };
  },
  computed: {
    ...mapGetters('currentUser', [
      'getUserApps',
      'getShowOrgSelectorData',
      'getUserCurrentApp'
    ]),
    ...mapGetters('cendynRegistry', [
      'getApplications',
      'getLoadingStatus'
    ]),
    userApplications() {
      return this.getUserApps.map(x => x.applicationId);
    },
    apps() {
      const response = this.getApplications
        .filter(x => x.showAppLaunch && x.applicationStatus)
        .map(x => ({
          ...x,
          urlLink: x.urlLink?.startsWith('http')
            ? x.urlLink
            : `https://${x.urlLink}`
        }));
      return response;
    }
  },
  created() {
    this.selectedApplication = this.getUserCurrentApp;
  },
  methods: {
    ...mapActions('currentUser', [
      'fetchUserPrefAsync',
      'fetchUserAppsAsync',
      'fetchUserRolesAsync',
      'fetchUserOrgsAsync',
      'setUserCurrentOrg'
    ]),
    async onSelectApplication(e, application) {
      this.selectedApplication = application;

      let orgSelectorData = this.getShowOrgSelectorData(application.name);
      let organizations = undefined;

      if (orgSelectorData.showOrganizationSelector){
        organizations = await this.fetchUserOrgsAsync({ appId: application.applicationId, saveToStore: false});
      }
      else {
        organizations = [{ accountId: orgSelectorData.organizationId }];
      }

      this.$refs.orgSelector.selectApplication({
        application,
        organizationIds: organizations.map(o => o.accountId),
        currentOrganization: this.currentOrganization,
        callback: this.fetchUserOrgsAsync({ appId: application.applicationId, saveToStore: false})
      });
    }
  }
};
</script>

<style lang="scss">
.application-cards {
  .card {
    height: 200px;
    min-width: 200px;
  }
}
//Move to Main Nav CSS
button#home_btn.btn {
  position: relative;
  svg path {
    stroke: #276ecd;
  }
  &:after {
    content: "";
    height: 1px;
    background-color: #276ecd;
    width: 20px;
    position: absolute;
    bottom: -13px;
    left: 13px;
  }
}
.fakeit {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  span {
    margin-top: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    color: #12263f;
    &:hover {
      color: #12263f;
    }
  }
}
</style>
